"use client";
import React, { useEffect } from "react";
import { myUidSignal } from "./application";
import { useSignal } from "@preact/signals-react";

export default function LandingPage() {
  const yearSignal = useSignal(2024);
  const showDatabaseSignal = useSignal(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      yearSignal.value = new Date().getFullYear();
    }
    showDatabaseSignal.value = !!myUidSignal.value;
  }, []);
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <header className="w-full flex items-center justify-between px-8 py-4 border-b">
        <div className="flex items-center space-x-4">
          <img
            src="/favicon.ico"
            alt="Memory Atlas Icon"
            className="w-10 h-10"
          />
          <h1 className="text-2xl font-bold">Memory Atlas</h1>
        </div>
        <div>
          {showDatabaseSignal.value ? (
            <a
              href="/db"
              className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors mr-4"
            >
              Access Database
            </a>
          ) : null}
          <a
            href="/start"
            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Learn More / Get Started
          </a>
        </div>
      </header>

      {/* Hero Section */}
      <main className="flex-1 flex flex-col items-center justify-center text-center px-4">
        <div className="mb-8">
          <img
            src="/favicon.ico"
            alt="Memory Atlas Icon"
            className="w-48 h-48 mx-auto"
          />
          <h2 className="text-4xl font-extrabold mt-4">
            Catalog, organize, and search your belongings
          </h2>
          <p className="text-lg mt-2 text-gray-700">
            ... into an AI-powered database using your voice
          </p>
        </div>

        {/* Embedded YouTube Video */}
        <div className="w-full max-w-2xl aspect-video mb-8">
          <iframe
            className="w-full h-full rounded-lg border"
            src="https://www.youtube.com/embed/xjJRB9i9220"
            title="YouTube video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        {/* How it Works Section */}
        <div className="max-w-3xl text-left">
          <h3 className="text-2xl font-bold mb-4">How It Works</h3>
          <ul className="list-disc pl-8 space-y-2 text-gray-700">
            <li>Speak directly into the app to describe your belongings.</li>
            <li>Automatically categorize items into a smart database.</li>
            <li>
              Search and ask questions about your belongings with natural
              language commands.
            </li>
            <li>Get insights, organize efficiently, and never lose track!</li>
          </ul>
        </div>
      </main>

      {/* Footer */}
      <footer className="w-full py-4 text-center border-t text-gray-500 text-sm">
        &copy; {yearSignal.value} Ian Bicking. All rights reserved.
      </footer>
    </div>
  );
}
